<template>
  <div>
    <Html :lang="actualLocale" :dir="head.htmlAttrs.dir">
      <template v-for="link in headLinks" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
      <slot />

      <LazyWidgetsWhatsappLink v-if="showWhatsapp" />
    </Html>
  </div>
</template>

<script setup>
  import { localeMappings } from '~/constants/locales';

  const { locale } = useI18n();
  const { $emitter } = useNuxtApp();
  const { isProduction } = useRuntimeConfig().public;
  const showWhatsapp = useLaunchDarkly('mx-whatsapp');
  const { updateOptimizelyExperimentValues } = useAnalyticsHelpers();

  const actualLocale = localeMappings[locale.value];

  if (isProduction) {
    useHead({
      script: [
        {
          src: 'https://optimizely.samsara.com/edge-client/v1/15547220277/18954260005',
          referrerpolicy: 'no-referrer-when-downgrade',
        },
      ],
    });
  }

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
  });

  const headLinks = computed(() => {
    const i18nHeadFiltered = head.value.link
      ? head.value.link.filter((link) => {
          return link.rel === 'canonical' ||
            link.hreflang === 'en' ||
            link.hreflang === 'x-default'
            ? link
            : '';
        })
      : null;

    return [...i18nHeadFiltered];
  });

  onMounted(() => {
    $emitter.on('select-value', (value) => {
      const listWrappers = document.querySelectorAll('.lw-wrapper');
      listWrappers.forEach((list) => {
        if (list.id === value && list.getAttribute('data-type') === 'Select') {
          list.style.display = 'flex';
        } else if (list.getAttribute('data-type') === 'Select') {
          list.style.display = 'none';
        }
      });
    });

    updateOptimizelyExperimentValues();
  });

  onUnmounted(() => {
    $emitter.off('select-value');
  });
</script>
